import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../firebase/config';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  TwitterAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword
} from 'firebase/auth';
import { useUser } from '../components/UserContext';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setGuid, setUsername, setGenerations, setModerations, authType } = useUser();
  
  const googleProvider = new GoogleAuthProvider();
  const twitterProvider = new TwitterAuthProvider();
  const appleProvider = new OAuthProvider('apple.com');

  const syncUserWithBackend = async (firebaseUser) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DB_API_URL}/sync-user`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          firebaseUid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName
        })
      });
      
      const data = await response.json();
      if (data.success) {
        setGuid(data.guid);
        setUsername(data.username);
        setGenerations(data.generations);
        setModerations(data.moderations);
        
        // Set cookies
        document.cookie = `guid=${data.guid}; path=/`;
        document.cookie = `username=${data.username}; path=/`;
        document.cookie = `generations=${data.generations}; path=/`;
        document.cookie = `moderations=${data.moderations}; path=/`;
        
        window.dispatchEvent(new Event('cookieChange'));
      }
    } catch (error) {
      console.error('Error syncing user:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        syncUserWithBackend(user);
      } else {
        // Only clear data if we're using Firebase auth
        if (authType === 'firebase') {
          setGuid(null);
          setUsername(null);
          setGenerations(null);
          setModerations(null);
        }
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [authType]);

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error('Google sign in error:', error);
    }
  };

  const signInWithTwitter = async () => {
    try {
      await signInWithPopup(auth, twitterProvider);
    } catch (error) {
      console.error('Twitter sign in error:', error);
    }
  };

  const signInWithApple = async () => {
    try {
      await signInWithPopup(auth, appleProvider);
    } catch (error) {
      console.error('Apple sign in error:', error);
    }
  };

  const signInWithEmail = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return { success: true, user: userCredential.user };
    } catch (error) {
      console.error('Email sign in error:', error);
      return { success: false, error: error.message };
    }
  };

  const signUpWithEmail = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      return { success: true, user: userCredential.user };
    } catch (error) {
      console.error('Email sign up error:', error);
      return { success: false, error: error.message };
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      setCurrentUser(null);
      // Clear all user data
      setGuid(null);
      setUsername(null);
      setGenerations(null);
      setModerations(null);
      
      // Clear cookies
      ['guid', 'username', 'generations', 'moderations', 'isMod'].forEach(cookieName => {
        document.cookie = `${cookieName}=;expires=${new Date(0).toUTCString()};path=/`;
      });
      window.dispatchEvent(new Event('cookieChange'));
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  const value = {
    currentUser,
    signInWithGoogle,
    signInWithTwitter,
    signInWithApple,
    signInWithEmail,
    signUpWithEmail,
    signOut
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext); 