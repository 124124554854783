import React, { useState, useRef, useEffect } from 'react';
import Pagination from './Pagination';
import { useUser } from './UserContext';
import { useAuth } from '../contexts/AuthContext';

const Footer = ({ page, setPage, isFiltered }) => {
    const [showForm, setShowForm] = useState(false);
    const [showLoginOptions, setShowLoginOptions] = useState(false);
    const [code, setCode] = useState('');
    const { setGenerations, setModerations, setUsername, user } = useUser();
    const { signInWithGoogle, signInWithTwitter, signInWithApple, signInWithEmail, signUpWithEmail } = useAuth();
    const inputRef = useRef(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [renderTime, setRenderTime] = useState(0);
    const [currentTime, setCurrentTime] = useState('');
    const dbURI = process.env.REACT_APP_DB_API_URL || 'http://192.168.0.83:3001';
    const [showEmailForm, setShowEmailForm] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const startTime = performance.now();
        const now = new Date();
        const formattedTime = now.toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' })
            .replace(',', ':')
            .replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3/$2/$1')
            .trim();
        setCurrentTime(formattedTime);

        return () => {
            const endTime = performance.now();
            setRenderTime((endTime - startTime).toFixed(2));
        };
    }, []);

    useEffect(() => {
        if (showForm && inputRef.current) {
            inputRef.current.focus();
            const inputElement = inputRef.current;
            inputElement.addEventListener('touchstart', () => inputElement.focus());
        }
        return () => {
            if (inputRef.current) {
                const inputElement = inputRef.current;
                inputElement.removeEventListener('touchstart', () => inputElement.focus());
            }
        };
    }, [showForm]);

    const handleCodeSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${dbURI}/check-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code })
            });
            const data = await response.json();
            if (data.valid) {
                setShowSuccess(true);
                setGenerations(data.generations);
                setModerations(data.moderations);
                setUsername(data.username);
                // console.log(typeof setGuid);
                // setGuid(data.guid);
                // console.log('Guid', data.guid);
                // setCookie('guid', data.guid, 30); // Set cookie to expire in 30 days
                document.cookie = `guid=${data.guid}; path=/`;
                // setCookie('generations', data.generations, 30); 
                // setCookie('moderations', data.moderations, 30); 
                
                setTimeout(() => {
                    setShowSuccess(false);
                }, 1500);
            } else {
                alert('Invalid code.');
            }
        } catch (error) {
            console.error('Error checking code:', error);
        }
        setShowForm(false);
    };

    const handleBackdropClick = () => {
        setShowForm(false);
    };

    const handleLoginClick = () => {
        setShowLoginOptions(!showLoginOptions);
    };

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setError('');
        
        const authFunction = isSignUp ? signUpWithEmail : signInWithEmail;
        const result = await authFunction(email, password);
        
        if (result.success) {
            setShowEmailForm(false);
            setShowLoginOptions(false);
            setEmail('');
            setPassword('');
        } else {
            setError(result.error);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            await signInWithGoogle();
            // If we get here, the sign-in was successful (no error thrown)
            setShowLoginOptions(false);
        } catch (error) {
            console.error('Google sign-in error:', error);
            // Optionally handle the error (show message, etc.)
        }
    };

    return (
        <div className="footer">
            <div className="footer-content">
                <div className="created-by">
                    created by: RobotPirateNinja 
                    <a href="https://discord.gg/YXEBj4nd" target="_blank" rel="noopener noreferrer">
                        <img 
                            src="/discord.png" 
                            alt="Discord" 
                            style={{ 
                                height: '16px', 
                                marginLeft: '5px',
                                verticalAlign: 'middle',
                                filter: 'brightness(1.2)', 
                                mixBlendMode: 'normal'     
                            }} 
                        />
                    </a>
                    <br />
                    owned and operated by: RPN Omnigalactic Insitutution as of: {currentTime} in {renderTime} ms
                </div>
                <div className="footer-pagination">
                    <Pagination page={page} setPage={setPage} />
                </div>
                {!user && (
                    <div className="auth-options">
                        <div className="enter-code" onClick={() => setShowForm(!showForm)}>Enter Code</div>
                        <div className="login-options" onClick={handleLoginClick}>
                            Login with Account
                        </div>
                    </div>
                )}
            </div>
            {showForm && (
                <>
                    <div className="code-form-backdrop" onClick={handleBackdropClick}></div>
                    <div className="code-form">
                        <form onSubmit={handleCodeSubmit}>
                            <label>enter code: </label>
                            <input 
                                type="text" 
                                value={code} 
                                onChange={(e) => setCode(e.target.value)} 
                                ref={inputRef}
                            />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </>
            )}
            {showSuccess && (
                <div className="code-form">
                    <div className="success-message">Code is valid!</div>
                </div>
            )}
            {showLoginOptions && (
                <>
                    <div className="code-form-backdrop" onClick={() => setShowLoginOptions(false)}></div>
                    <div className="login-options-modal">
                        <button onClick={() => setShowEmailForm(true)} className="login-button email">
                            {/* <img src="/email-icon.png" alt="Email" /> */}
                            📧 Continue with Email
                        </button>
                        <button onClick={handleGoogleSignIn} className="login-button google">
                            <img src="/google_small.png" alt="Google" />
                          Continue with Google
                        </button>
                        {/* <button onClick={signInWithTwitter} className="login-button twitter">
                            <img src="/x-icon.png" alt="X" />
                            Continue with X
                        </button>
                        <button onClick={signInWithApple} className="login-button apple">
                            <img src="/apple-icon.png" alt="Apple" />
                            Continue with Apple
                        </button> */}
                    </div>
                </>
            )}
            {showEmailForm && (
                <>
                    <div className="code-form-backdrop" onClick={() => setShowEmailForm(false)}></div>
                    <div className="email-form-modal">
                        <h3>{isSignUp ? 'Create Account' : 'Sign In'}</h3>
                        {error && <div className="error-message">{error}</div>}
                        <form onSubmit={handleEmailSubmit}>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button type="submit">
                                {isSignUp ? 'Sign Up' : 'Sign In'}
                            </button>
                        </form>
                        <div className="form-footer">
                            <button 
                                className="switch-form" 
                                onClick={() => setIsSignUp(!isSignUp)}
                            >
                                {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Footer;
