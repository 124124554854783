import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, useNavigate, Link } from 'react-router-dom';
import SingleResponse from './single_response';
import FilteredResponses from './filtered_responses';
import Navigation from './components/Navigation';
import FormComponent from './components/SubmitForm';
import WalterCronkiteResult from './components/WalterCronkiteResult';
import AudioControls from './components/AudioControls';
import Footer from './components/Footer';
import ResponseText from './components/ResponseText';
import ResponseInfoMain from './components/ResponseInfoMain';
import User from './components/User';
import UserPage from './components/UserPage';
import { useUser } from './components/UserContext';

function App() {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false); 
    const [result] = useState(''); 
    const [loadingMessage, setLoadingMessage] = useState('');
    const [responses, setResponses] = useState([]);
    const [page, setPage] = useState(1);
    const [option, setOption] = useState('haiku');
    const [timer, setTimer] = useState(0); 
    const [promptError, setPromptError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [currentAudio, setCurrentAudio] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [showControls, setShowControls] = useState(false);
    const [voteCast, setVoteCast] = useState(false)
    const [moderations, setModerations] = useState(null);
    const [generations, setGenerations] = useState(null);
    const { user, isMod, setIsMod, isUserHidden, setIsUserHidden } = useUser(); 

    const dbURI = process.env.REACT_APP_DB_API_URL || 'https://haikuthenews-d19750c291e2.herokuapp.com';
    
    const [showMore, setShowMore] = useState(false); 
    const s3URI = process.env.REACT_APP_S3_URI || 'https://haikuthenews-responses.s3.amazonaws.com';

    const [commentCounts, setCommentCounts] = useState({});

    useEffect(() => {
      const fetchCommentCounts = async () => {
        const counts = {};
        for (const response of responses) {
          const res = await fetch(`${dbURI}/comment-count/${response._id}`);
          const data = await res.json();
          counts[response._id] = data.count;
        }
        setCommentCounts(counts);
      };

      if (responses.length > 0) {
        fetchCommentCounts();
      }
    }, [responses, dbURI]);

    const fetchResponses = async () => {
        const res = await fetch(`${dbURI}/responses?page=${page}&limit=12`);
        const data = await res.json();
        setResponses(data);
    };

    const getCookieValue = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    useEffect(() => { 
        fetchResponses();
        window.scrollTo(0, 0); 
    }, [page]);

    useEffect(() => { 
        let interval;
        if (loading && !promptError) {
            interval = setInterval(() => {
                setTimer(prev => prev + 1);
                switch (timer) {
                    case 10:
                        const quips = ["...mostly...", "...more likely that not...","...given optimal conditions...","...if billions of things work correctly...","bitcoin"]
                        const selected_quip = quips[Math.floor(Math.random() * quips.length)];
                        setLoadingMessage(selected_quip);
                        break;
                    case 20:
                        const quips2 = ["Look, no one is perfect.", "Everyone has their faults.","A simple calculation this is not.", "This wasn't possible when I was a kid", "bitcoin"]
                        const selected_quip2 = quips2[Math.floor(Math.random() * quips2.length)];
                        setLoadingMessage(selected_quip2);
                        break;
                    case 30:
                        const quips3 = ["Like not even close.", "We're doing a lot of stuff...", "Heck, this wasn't possible a year ago.", "But I did dream of it, one day.", "bitcoin"]
                        const selected_quip3 = quips3[Math.floor(Math.random() * quips3.length)];
                        setLoadingMessage(selected_quip3);
                        break;
                    case 40:
                        const quips4 = ["We didn't even have the internet.","Sooo many calculations...", "Having a brain in a jar to talk to.", "How long does it take to count 400B parameters, anyway?", "****seven supercomputers whirring***", "bitcoin"]
                        const selected_quip4 = quips4[Math.floor(Math.random() * quips4.length)];
                        setLoadingMessage(selected_quip4);
                        break;
                    case 50:
                        const quips5 = ["Seriously, that was before dial-up. Late 1900's...", "Non-deterministic functions and what not...","It's like rolling dice. 400,000,000,000 dice. In 60 seconds.", "bitcoin"]
                        const selected_quip5 = quips5[Math.floor(Math.random() * quips5.length)];
                        setLoadingMessage(selected_quip5);
                        break;
                    case 55:
                        const quips6 = ["1993", "Magic: The Gathering was created.", "Then came the World Wide Web, and 90210 and Cheers 'ended'","The Cowboys won the Superbowl.","River Phoenix dies on my birthday.", "Nirvana was rockin' it out in Jurassic Park","Bill Nye the Science Guy hits syndication", "Windows NT 3.1 was *state of the art*","The Infinite Improbability drive of functional coding.", "bitcoin"]
                        const selected_quip6 = quips6[Math.floor(Math.random() * quips6.length)];
                        setLoadingMessage(selected_quip6);
                        break;
                    case 60:
                        setLoadingMessage("Aaaaaany ssssseeeecccooonnnd...(stories take longer)");
                        break;
                    case 70:
                        setLoadingMessage("Hrrmmm...");
                        break;
                    case 80:
                        setLoadingMessage("Like I said, perfection is a goal, not a requirement.");
                        break;
                    case 90:
                        setLoadingMessage("We're idempotent, but we're not perfect.");
                        break;
                    case 100:
                        setLoadingMessage("At this point there's probably a problem.");
                        break;
                    case 110:
                        setLoadingMessage("Well...this is awkward.");
                        break;
                    case 120:
                        setLoadingMessage("It's over man. Game over. Try again. (refresh the page and try again");
                        break;
                    case 240:
                        setLoadingMessage("Are you still here? It failed. Refresh, dude or dudette or dudeother, get on with your life.");
                        break;
                }
            }, 1000);
        } else if (!loading && timer !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [loading, timer]);

    useEffect(() => {
        const updateImageSources = () => {
            const images = document.querySelectorAll('.response-card img');
            images.forEach(img => {
                const originalSrc = img.getAttribute('data-original-src');
                if (originalSrc) {
                    if (window.innerWidth >= 1200) {
                      img.src = originalSrc.replace('.png_sml.jpg', '.png_med.jpg');
                    } else {
                      img.src = originalSrc;
                    }
                }
            });
        };

        window.addEventListener('resize', updateImageSources);
        updateImageSources(); // Initial call

        return () => window.removeEventListener('resize', updateImageSources);
    }, []);

    // useEffect(() => {
    //     console.log('isMod:', isMod);
    // }, [isMod]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setLoadingMessage('Usually done under 60 seconds...');

        setTimer(0); 
        const formData = new FormData();
        const fileInput = document.getElementById('fileInput');
        if (fileInput && fileInput.files[0]) {
            formData.append('image', fileInput.files[0]);
        } else {
            formData.append('url', url);
        }
        formData.append('option', option);
        formData.append('guid', getCookieValue('guid')); 
        // formData.append('username', getCookieValue('username') || 'anonywah');
        formData.append('username', getCookieValue('username'));

        let response;
        try {
            response = await fetch(`${dbURI}/scrape`, {

                method: 'POST',
                body: formData,
            });
        } catch (error) {
            console.error('Fetch failed:', error);
            setPromptError(true);
            setErrorMessage(error.message);
            setLoading(false);
            return;
        }
        const data = await response.json();
        if (data.createdAt) {
            setLoadingMessage('Received response...');
            document.cookie = `generations=${data.generations}; path=/`;
            setGenerations(data.generations);
            window.dispatchEvent(new Event('cookieChange'));
            setLoading(false);
            setUrl('');
            navigate(`/response/${data.createdAt}`);
        } else {
            setLoadingMessage('Error: No valid response received. Set Message');
            setLoading(false);
        }
    };

    const handleAudioPlay = (audio) => {
        if (currentAudio && currentAudio !== audio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
        }
        setCurrentAudio(audio);
        setIsPaused(false);
        setDuration(audio.duration);
        setShowControls(true);
        audio.ontimeupdate = () => setCurrentTime(audio.currentTime);
    };

    const vote = async (responseId, direction) => {
        const guid = getCookieValue('guid'); // Retrieve guid from cookies
        const response = await fetch(`${dbURI}/vote/${responseId}/${direction}`, { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ guid })
        });
        const data = await response.json();
        if (data.success) {
            document.cookie = `moderations=${data.moderations}; path=/`;
            setModerations(data.moderations); // Update state
            window.dispatchEvent(new Event('cookieChange'));
        }
        setVoteCast(true); // Set voteCast to true when a vote is cast
        fetchResponses();
    };

    return (
        <Routes>
            <Route path="/response/:createdAt" element={<SingleResponse />} />
            <Route path="/author/:author?/institution/:institution?" element={<FilteredResponses />} />
            <Route path="/type/:type" element={<FilteredResponses />} />
            <Route path="/author/:author" element={<FilteredResponses />} />
            <Route path="/institution/:institution" element={<FilteredResponses />} />
            <Route path="/user/:username" element={<UserPage />} />
            <Route path="/" exact element={
                <div className="App">
                    <Navigation isUserHidden={isUserHidden} user={user} />
                    <User />
                    <header className="App-header">
                        <FormComponent
                            handleSubmit={handleSubmit}
                            url={url}
                            setUrl={setUrl}
                            option={option}
                            setOption={setOption}
                            loadingMessage={loadingMessage}
                        />
                        {loading && <p>{loadingMessage} ({timer}s/~60s)</p>} 
                        {promptError && <p>Error: No valid response received. Hard code.</p>}
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                        <div style={{ textAlign: 'left', width: '66.67%', margin: '0 auto', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                            {result && <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{result}</pre>} 
                        </div>
                        <div className="responses">
                            {responses.length > 0 ? (
                                responses.map((response, index) => (
                                    <div key={index} id={`${response.createdAt}`} className={`response-card ${response.type}`}>
                                        <ResponseInfoMain response={response} />
                                        <ResponseText response={response} index={index} handleAudioPlay={handleAudioPlay} />
                                        <div className='generated-image'>
                                        {response.generatedImage && (
                                            <a href={response.generatedImage.replace('.png', '.png_lrg.jpg')} target="_blank" rel="noopener noreferrer">
                                                <img src={`${response.generatedImage.replace('.png', '.png_sml.jpg')}`} alt="Generated" data-original-src={`${response.generatedImage.replace('.png', '.png_sml.jpg')}`} />
                                            </a>
                                        )}
                                        </div>
                                        <div className={`walter-cronkite-table-wrapper-main ${showMore ? 'show' : 'hide'}`}>
                                        {response.walterCronkiteResult ? (
                                            <WalterCronkiteResult response={response} />
                                        ) : (
                                            <div className='uploaded_image'>
                                                <br />
                                                {response.url ? (
                                                    <div className='uploaded-image-url'>
                                                        <a href={response.url} target="_blank" rel="noopener noreferrer">
                                                            <img src={`${s3URI}/${response.screenshot}`} alt={`Holder`} data-original-src={`${s3URI}/${response.screenshot}`} />
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <div className='uploaded-image-image'>
                                                        <a href={`${s3URI}/${response.screenshot}`} target="_blank" rel="noopener noreferrer">
                                                            <img src={`${s3URI}/${response.screenshot}`} alt={`Holder`} data-original-src={`${s3URI}/${response.screenshot}`} />
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        </div>
                                        <div className="expand-main-container" style={{ textAlign: 'center', fontFamily: 'monospace' }} >
                                            <div style={{position: 'relative', bottom: '5px', right: '5px', textAlign: 'center'}} onClick={() => setShowMore(!showMore)}>
                                                <span className={showMore ? 'unexpand-button' : 'expand-button'}>
                                                    {showMore ? '^^ less ^^' : '∨∨ more ∨∨'}
                                                </span>
                                            </div>          
                                            <div style={{ position: 'relative', bottom: '5px', right: '5px', textAlign: 'right'}}>
                                                <span className="metal linear">
                                                    <Link to={`/response/${response.createdAt}`}>
                                                        {commentCounts[response._id] ? `comments (${commentCounts[response._id]})` : 'comment?'}
                                                    </Link>   
                                                </span>
                                            </div>
                                        </div>
                                        {user && isMod && ( 
                                            <>
                                                <button className="up-button" onClick={() => vote(response._id, 'up')}></button>
                                                <button className="down-button" onClick={() => vote(response._id, 'down')}></button>
                                            </>
                                        )} 
                                    </div>
                                ))
                            ) : (
                                <div>
                                    <div><span style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}><p>No responses found...yet. Probably will be here soon.</p></span></div>
                                    <div className="custom-loader" style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}></div>
                                </div>
                            )}
                        </div>
                        <Footer page={page} setPage={setPage} isFiltered={false} />
                            <AudioControls
                                currentAudio={currentAudio}
                                setCurrentAudio={setCurrentAudio}
                                isPaused={isPaused}
                                setIsPaused={setIsPaused}
                                currentTime={currentTime}
                                setCurrentTime={setCurrentTime}
                                duration={duration}
                                setDuration={setDuration}
                                showControls={showControls}
                                setShowControls={setShowControls}
                                handleAudioPlay={handleAudioPlay}
                            />
                        </header>
                    </div>
                } />
            </Routes>
    );
}

export default App;